import React from 'react';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';
import content from './maskFeaturesContent';

const Container = styled.div`
  margin-top: 108px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 40px;
  }
`;

const Title = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.navy};
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const Text = styled.p`
  margin: 0px;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.navy};
`;

const MaskFeatures = () => (
  <Container>
    {content.map(({ title, text }) => (
      <div key={title}>
        <Title>
          {title}
        </Title>
        <Text>
          {text.split('\n').map(textPart => (<span key={textPart}>{textPart}<br /></span>))}
        </Text>
      </div>
    ))}
  </Container>
);

export default MaskFeatures;
