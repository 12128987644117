import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, Button } from '../../home/v3/styledComponents';
import routesMap from '../../../Routes';
import withOrderContext from '../../../withOrderContext';
import { pricingInverse } from '../../../services/pricing';
import Select from '../../Select';

const Container = styled.div`
  margin-left: 71px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const Title = styled.h1`
  margin: 0px 0px 20px;
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 32px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const TextLine = styled.p`
  margin: 0;
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: ${colors.green};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const PriceLabel = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.green};
  margin-left: 8px;
`;

const QuantityLabel = styled.p`
  margin: 0px 0px 30px;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.navy};
`;

const QuantitySelector = styled.p`
  margin: 0px 0px 10px;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Text = styled.p`
  margin: 0px;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.navy};
  margin-bottom: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 30px;
  }
`;

const StyledButton = styled(Button)`
  margin: 10px 0px 0px;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const QuantitiesContainer = styled.div`
  margin-bottom: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const TextError = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.error};
  text-align: center;
  margin-bottom: 10px;
`;

const QuantityContainer = styled.div`
  margin-bottom: 20px;
`;

const quantitiesOptions = [0, 1, 2, 5, 10, 15, 20, 30, 50].map(value => ({ value, label: value }));
const minimalQuantity = 5;

export const formatMasks = (quantities) => {
  const maskItems = {
    adultNavy: pricingInverse['7.mask-fab-2'],
    adultBeige: pricingInverse['7.mask-fab-3'],
    kidNavy: pricingInverse['7.mask-fab-4'],
    kidBeige: pricingInverse['7.mask-fab-5'],
  };
  return Object.keys(quantities)
    .map(option => ({
      ...maskItems[option],
      quantity: quantities[option].value,
    }))
    .filter(item => item.quantity > 0);
};

const getQuantity = quantities => Object.keys(quantities)
  .reduce((sum, option) => sum + quantities[option].value, 0);

const options = {
  adultNavy: quantitiesOptions[0],
  adultBeige: quantitiesOptions[0],
  kidNavy: quantitiesOptions[0],
  kidBeige: quantitiesOptions[0],
};

const labels = {
  adultNavy: 'adulte bleu',
  adultBeige: 'adulte beige',
  kidNavy: 'enfant bleu',
  kidBeige: 'enfant beige',
};

const renderText = () => (
  <Text>
    Ce masque en tissu 100% coton est lavable et réutilisable. Fabriqué à la main par nos
    couturiers en France, il ne fait pas mal aux oreilles. Afin de garantir une protection
    maximale, nous vous conseillons d’en changer 4 à 5 fois par jour et de le laver entre
    chaque usage. Ce masque ne vous dispense pas des gestes barrières : se laver régulièrement
    les mains, éviter de se toucher le visage et garder ses distances.<br />
    <br />
    L’approvisionnement des soignants reste notre priorité donc les délais
    d’envois peuvent être rallongés.<br />
    <br />
    <b>
      Attention : Il ne s‘agit en aucun cas d‘un masque médical et n‘a pas vocation à le remplacer.
    </b>
  </Text>
);

const MaskProduct = ({ orderContext: { setMasks } }) => {
  const [quantities, setQuantities] = useState(options);
  const [error, setError] = useState(false);
  const canOrder = getQuantity(quantities) >= minimalQuantity;
  return (
    <Container>
      <Title>Masque 100% coton fait main<br />Soutenons l‘artisanat français 🇫🇷</Title>
      <TextLine>
        <Price>10€</Price>
        <PriceLabel>l‘unité</PriceLabel>
      </TextLine>
      <QuantityLabel>5 masques minimum</QuantityLabel>
      <QuantitiesContainer>
        {Object.keys(quantities).map(option => (
          <QuantityContainer key={option}>
            <QuantitySelector>Nombre de masques {labels[option]}</QuantitySelector>
            <Select
              value={quantities[option]}
              onChange={newQuantity => setQuantities({
                ...quantities,
                [option]: newQuantity,
              })}
              options={quantitiesOptions}
              isSearchable={false}
            />
          </QuantityContainer>
        ))}
      </QuantitiesContainer>
      {renderText()}
      {error && !canOrder && (<TextError>Le minimum de commande est de 5 masques</TextError>)}
      <StyledButton
        navy
        isAvailable={canOrder}
        onClick={() => {
          if (canOrder) {
            setMasks(formatMasks(quantities));
            navigate(routesMap.MasksForm.url);
          } else {
            setError(true);
          }
        }}
      >Commander</StyledButton>
    </Container>
  );
};

MaskProduct.propTypes = {
  orderContext: PropTypes.shape({
    setMasks: PropTypes.func.isRequired,
  }).isRequired,
};

export default withOrderContext(MaskProduct);
