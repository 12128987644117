/* eslint-disable max-len */
export default [
  {
    title: 'Comment mettre le masque en tissu ?',
    text: `Le masque en tissu doit être appliqué sur le visage, il doit recouvrir le nez et le menton. Il faut ensuite fixer les lanières en tissu autour de vos oreilles. Si vous portez des lunettes, enlevez-les le temps de mettre le masque en tissu.
Vérifiez que le masque en tissu soit correctement mis en place.
Une fois bien ajusté, évitez de toucher le masque en tissu avec les mains. Chaque fois que vous touchez le masque, il est recommandé de se laver les mains à l’eau et au savon ou exercer une friction avec une solution hydroalcoolique.
Pour éviter toute contamination lors du retrait de votre masque, il doit être correctement retiré, isolé, pour être lavé. Pour cela il est recommandé de :
- Si vous portez des gants, bien les retirer avant de toucher le masque
- Se laver les mains à l’eau et au savon ou avec une solution hydroalcoolique
- Retirer le masque en tissu en le saisissant par l’arrière grâce aux élastiques sans toucher la partie avant du masque
- Placer le masque en tissu à jeter dans un contenant spécifique comme un sac plastique propre
- Se laver les mains à l’eau et au savon ou avec une solution hydroalcoolique.`,
  },
  {
    title: 'Précautions',
    text: `Fabriqué en France, dans du coton 100% en beige ou bleu marine. Modèle AFNOR.
- Lavage à 60°C minimum durant un cycle de 30 min en utilisant votre lessive habituelle, l’assouplissant n’étant pas préconisé, privilégier le lavage à 90°C, ne pas tordre pour essorer.
- Sécher le masque en tissu au sèche-linge dans les 2 heures après le lavage. Séchage à l’air libre et repassage vapeur à température maximum.
- Laver aussi souvent que nécessaire.
- Pas de nettoyage à l'eau de javel
- Possibilité de nettoyage "à sec" en appliquant la vapeur de votre fer à repasser (la vapeur sort à + de 100°C)
- Ne plus utiliser si le masque en tissu est usé, présente une déchirure, n'est plus confortable, empêche la respiration, etc.
- Se laver les mains à l’eau et au savon ou exercez une friction avec une solution hydroalcoolique avant toute manipulation du masque, évitez de toucher l’intérieur du masque
- Mettre le masque en tissu en vous aidant des liens. Une fois en place, ne pas le toucher, ni l’enlever
- Changer de masque toutes les 2 à 3 heures maximum et le mettre immédiatement dans un sac jusqu’au lavage
- Après avoir enlevé votre masque, se laver les mains jusqu’aux coudes
- Le port du masque n’exonère pas des gestes barrières, respecter les mesures d'hygiène, garder une distance de 1m et se laver les mains le plus souvent possible`,
  },
  {
    title: 'Livraison',
    text: `Les expéditions se font dans toute la France. Elles sont réalisées au fur et à mesure de la fabrication des commandes. Nous dépendons de La Poste, donc nous ne pouvons pas garantir de délai de livraison.

Si vous souhaitez recevoir plus rapidement vos commandes, nous pouvons commander un coursier pour vous livrer (en Ile-de-France, seulement). Le surcoût sera à votre charge.`,
  },
  {
    title: 'Délais de fabrication',
    text: 'Les délais de fabrication sont en général de quelques jours. Cependant suite aux fortes demandes, ils peuvent être plus longs.',
  },
  {
    title: 'Retour/annulation',
    text: `Pour des raisons d'hygiène, ce masque ne pourra être ni repris, ni échangé, ni remboursé.
La fabrication se fait au fur et à mesure des commandes. Nous ne pouvons donc pas accepter les annulations et demandes de remboursement.`,
  },
];
